// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brdr-btm{
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form-switch .form-check-input{
  width: 3.5em;
  height: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/components/Settings.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".brdr-btm{\r\n  border-bottom: 1px solid #cfcfcf;\r\n  padding-bottom: 10px;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.form-switch .form-check-input{\r\n  width: 3.5em;\r\n  height: 1.5em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
