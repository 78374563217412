// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services .row [class^="col-"]{
  margin-bottom: 20px;
}

.button-box{
  text-align: center;
  box-shadow: 0px 0px 4px #e7e7e7;
  min-height: 135px;
  border: 1px solid #e5e5e5;
}

.app-body .button-box img{
  font-size: 36px;
  margin-bottom: 10px;
  min-height: 50px;
}

.button-box h6{
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
}

.button-box a{
  text-decoration: none;
  color: #000;
  display: block;
  padding: 20px;
}

.button-box.disable{
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/components/Services.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,+BAA+B;EAC/B,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,cAAc;EACd,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".services .row [class^=\"col-\"]{\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.button-box{\r\n  text-align: center;\r\n  box-shadow: 0px 0px 4px #e7e7e7;\r\n  min-height: 135px;\r\n  border: 1px solid #e5e5e5;\r\n}\r\n\r\n.app-body .button-box img{\r\n  font-size: 36px;\r\n  margin-bottom: 10px;\r\n  min-height: 50px;\r\n}\r\n\r\n.button-box h6{\r\n  margin-bottom: 0;\r\n  font-size: 14px;\r\n  font-weight: 600;\r\n}\r\n\r\n.button-box a{\r\n  text-decoration: none;\r\n  color: #000;\r\n  display: block;\r\n  padding: 20px;\r\n}\r\n\r\n.button-box.disable{\r\n  pointer-events: none;\r\n  opacity: 0.5;\r\n  cursor: not-allowed;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
