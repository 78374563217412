// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-password input[type="password"]{
  /* border: 1px solid #e7e7e7; */
}

.change-password form .form-group .icon-font{
  position: absolute;
  right: 10px;
  top: 20px;
}

.back-to-login{
  position: fixed;
  bottom: 0;
  box-shadow: 0px 0px 4px #ccc;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background-color: #fff;
}

.back-to-login a{
  text-decoration: none;
  /* color: #6d0000; */
  font-weight: 500;
}
.change-password a,
a{
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/ForgotPassword.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,eAAe;EACf,SAAS;EACT,4BAA4B;EAC5B,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,gBAAgB;AAClB;AACA;;EAEE,qBAAqB;AACvB","sourcesContent":[".change-password input[type=\"password\"]{\r\n  /* border: 1px solid #e7e7e7; */\r\n}\r\n\r\n.change-password form .form-group .icon-font{\r\n  position: absolute;\r\n  right: 10px;\r\n  top: 20px;\r\n}\r\n\r\n.back-to-login{\r\n  position: fixed;\r\n  bottom: 0;\r\n  box-shadow: 0px 0px 4px #ccc;\r\n  width: 100%;\r\n  text-align: center;\r\n  padding: 10px 0;\r\n  background-color: #fff;\r\n}\r\n\r\n.back-to-login a{\r\n  text-decoration: none;\r\n  /* color: #6d0000; */\r\n  font-weight: 500;\r\n}\r\n.change-password a,\r\na{\r\n  text-decoration: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
