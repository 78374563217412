// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner-app-top{
  padding: 23px 15px;
}

.inner-app-top svg{
  color: #333547;
}

.inner-app-top .scroll-back svg{
  color: #818181;
}`, "",{"version":3,"sources":["webpack://./src/components/InnerAppTop.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".inner-app-top{\r\n  padding: 23px 15px;\r\n}\r\n\r\n.inner-app-top svg{\r\n  color: #333547;\r\n}\r\n\r\n.inner-app-top .scroll-back svg{\r\n  color: #818181;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
