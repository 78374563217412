// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-user-profile-password-form .form-group {
  margin-bottom: 20px;
}

.edit-user-profile-password-form .form-group span{
  color: #c30000;
  font-weight: 600;
}

.edit-user-profile-password-form svg{
  font-size: 16px;
  color:#8f8f8f;
}

.min-width-100{
  min-width:100px;
}`, "",{"version":3,"sources":["webpack://./src/components/ChangePassword.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,aAAa;AACf;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".edit-user-profile-password-form .form-group {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.edit-user-profile-password-form .form-group span{\r\n  color: #c30000;\r\n  font-weight: 600;\r\n}\r\n\r\n.edit-user-profile-password-form svg{\r\n  font-size: 16px;\r\n  color:#8f8f8f;\r\n}\r\n\r\n.min-width-100{\r\n  min-width:100px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
