// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-certifiate input[type="file"]{
  display: inline-block;
  font-size: 0;
}

input[type="file"]::-webkit-file-upload-button {
  /* border: 2px solid #b4b4b4; */
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  border: none;
  padding: 6px 10px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

input[type="file"]::-webkit-file-upload-button:hover {
  /* border: 2px solid #535353; */
  color: #fff;
}

span.close{
  margin-top: 8px;
  cursor: pointer;
}

.upload-certifiate h5{
  display: flex;
  justify-content: flex-end;
}

.upload-certifiate h5+span{
  font-size: 12px;
}

.upload-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  text-decoration: none;
}

.choose-file label{
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
  overflow: hidden;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/UploadCertificates.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,eAAe;EACf,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,+BAAuB;EAAvB,uBAAuB;AACzB;;AAEA;EACE,+BAA+B;EAC/B,WAAW;AACb;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".upload-certifiate input[type=\"file\"]{\r\n  display: inline-block;\r\n  font-size: 0;\r\n}\r\n\r\ninput[type=\"file\"]::-webkit-file-upload-button {\r\n  /* border: 2px solid #b4b4b4; */\r\n  border-radius: 4px;\r\n  color: #fff;\r\n  cursor: pointer;\r\n  font-size: 16px;\r\n  outline: none;\r\n  border: none;\r\n  padding: 6px 10px;\r\n  transition: all 1s ease;\r\n}\r\n\r\ninput[type=\"file\"]::-webkit-file-upload-button:hover {\r\n  /* border: 2px solid #535353; */\r\n  color: #fff;\r\n}\r\n\r\nspan.close{\r\n  margin-top: 8px;\r\n  cursor: pointer;\r\n}\r\n\r\n.upload-certifiate h5{\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\n\r\n.upload-certifiate h5+span{\r\n  font-size: 12px;\r\n}\r\n\r\n.upload-disabled {\r\n  opacity: 0.5;\r\n  cursor: not-allowed;\r\n  pointer-events: none;\r\n  text-decoration: none;\r\n}\r\n\r\n.choose-file label{\r\n  white-space: nowrap;\r\n  text-overflow: ellipsis;\r\n  width: 180px;\r\n  overflow: hidden;\r\n  line-height: normal;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
