// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ngolist-box{
  margin-top: 10px;
    margin-bottom: 50px;
    border: 1px solid #eeeeee;
    padding: 20px;
}

.ngolist-box .button-box{
  margin-bottom: 20px;
  min-height: 152px;
}

.ngolist-box .button-box a{
  padding: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/NgoList.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;IACd,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;AACjB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".ngolist-box{\r\n  margin-top: 10px;\r\n    margin-bottom: 50px;\r\n    border: 1px solid #eeeeee;\r\n    padding: 20px;\r\n}\r\n\r\n.ngolist-box .button-box{\r\n  margin-bottom: 20px;\r\n  min-height: 152px;\r\n}\r\n\r\n.ngolist-box .button-box a{\r\n  padding: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
