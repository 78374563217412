// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-all.choose-file .not-ellipse label {
  color: #818181;
  position: absolute;
  padding: 10px;
  font-size: 12px;
  z-index: 1;
  text-overflow: inherit;
}

.form-all.choose-file .not-ellipse textarea {
  padding-top: 1.825rem;
}`, "",{"version":3,"sources":["webpack://./src/components/InitialHistory.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".form-all.choose-file .not-ellipse label {\r\n  color: #818181;\r\n  position: absolute;\r\n  padding: 10px;\r\n  font-size: 12px;\r\n  z-index: 1;\r\n  text-overflow: inherit;\r\n}\r\n\r\n.form-all.choose-file .not-ellipse textarea {\r\n  padding-top: 1.825rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
