// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-footer{
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  /* height: 50px; */
  z-index: 1;
  box-shadow: 0 0 20px rgba(0,0,0,.15);
  background: #fff;
}

.app-footer svg{
  display: block;
  margin: 0 auto;
}

.app-footer .action-items{
  padding:15px 0;
}

.app-footer .action-items a{
  color: #717171;
  text-decoration: none;
  line-height: 10px;
}

.app-footer .action-items a span{
  font-size: 12px;
}

.footerLogo img{
  height: 80px;
  max-width: 150px;
}

`, "",{"version":3,"sources":["webpack://./src/components/AppFooter.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,SAAS;EACT,QAAQ;EACR,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,oCAAoC;EACpC,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".app-footer{\r\n  position: fixed;\r\n  left: 0;\r\n  bottom: 0;\r\n  right: 0;\r\n  margin: auto;\r\n  width: 100%;\r\n  /* height: 50px; */\r\n  z-index: 1;\r\n  box-shadow: 0 0 20px rgba(0,0,0,.15);\r\n  background: #fff;\r\n}\r\n\r\n.app-footer svg{\r\n  display: block;\r\n  margin: 0 auto;\r\n}\r\n\r\n.app-footer .action-items{\r\n  padding:15px 0;\r\n}\r\n\r\n.app-footer .action-items a{\r\n  color: #717171;\r\n  text-decoration: none;\r\n  line-height: 10px;\r\n}\r\n\r\n.app-footer .action-items a span{\r\n  font-size: 12px;\r\n}\r\n\r\n.footerLogo img{\r\n  height: 80px;\r\n  max-width: 150px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
