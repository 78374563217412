// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-box .prescription img{
  max-height: 135px;
  object-fit: contain;
}

div.file {
  position: relative;
  overflow: hidden;
}

.file input[type="file"] {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.prescription{
  padding: 20px 20px 0;
  position: relative;
}

.prescription p{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-delete{
  position: absolute;
  right: 7px;
  top: 7px;
}

/* .btn-delete svg{
  color: black;
} */`, "",{"version":3,"sources":["webpack://./src/components/janani/JananiUploadPrescription.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,UAAU;EACV,QAAQ;EACR,MAAM;AACR;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;AACV;;AAEA;;GAEG","sourcesContent":[".button-box .prescription img{\r\n  max-height: 135px;\r\n  object-fit: contain;\r\n}\r\n\r\ndiv.file {\r\n  position: relative;\r\n  overflow: hidden;\r\n}\r\n\r\n.file input[type=\"file\"] {\r\n  position: absolute;\r\n  font-size: 50px;\r\n  opacity: 0;\r\n  right: 0;\r\n  top: 0;\r\n}\r\n\r\n.prescription{\r\n  padding: 20px 20px 0;\r\n  position: relative;\r\n}\r\n\r\n.prescription p{\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.btn-delete{\r\n  position: absolute;\r\n  right: 7px;\r\n  top: 7px;\r\n}\r\n\r\n/* .btn-delete svg{\r\n  color: black;\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
