// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-container{
  max-width: 375px;
  margin: 0 auto;
}

.App-header h2{
  font-size: 22px;
  color: #212529;
}

.App-header img{
  max-width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/Landing.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".splash-container{\r\n  max-width: 375px;\r\n  margin: 0 auto;\r\n}\r\n\r\n.App-header h2{\r\n  font-size: 22px;\r\n  color: #212529;\r\n}\r\n\r\n.App-header img{\r\n  max-width: 150px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
