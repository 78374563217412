// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-schedule .form-group {
  margin-bottom: 20px;
}

.create-schedule form label.pos {
  color: #818181;
  position: absolute;
  padding: 10px;
  font-size: 12px;
  z-index: 1;
}

.create-schedule form input.pos {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

select.pos {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
  position: relative;
  appearance: auto;
  color: #818181;
  padding-left: 7px;
}
.create-schedule .add-more{
  right: 0;
  top: -8px;
}

.create-schedule .add-more button{
  padding: 2px 5px;
  margin: 0;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/CreateSchedule.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;EACrB,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,eAAe;AACjB","sourcesContent":[".create-schedule .form-group {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.create-schedule form label.pos {\r\n  color: #818181;\r\n  position: absolute;\r\n  padding: 10px;\r\n  font-size: 12px;\r\n  z-index: 1;\r\n}\r\n\r\n.create-schedule form input.pos {\r\n  padding-top: 1.825rem;\r\n  padding-bottom: 0.625rem;\r\n}\r\n\r\nselect.pos {\r\n  padding-top: 1.825rem;\r\n  padding-bottom: 0.625rem;\r\n  position: relative;\r\n  appearance: auto;\r\n  color: #818181;\r\n  padding-left: 7px;\r\n}\r\n.create-schedule .add-more{\r\n  right: 0;\r\n  top: -8px;\r\n}\r\n\r\n.create-schedule .add-more button{\r\n  padding: 2px 5px;\r\n  margin: 0;\r\n  font-size: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
