// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-awareness-survey.form-all form label{
  position: relative;
}

.rating-caption{
  font-size: 12px;
  color: #818181;
}

.rating-value{
  font-size: 12px;
}

.rc-slider{
  /* margin: 0 6px; */
}

.rc-slider-handle{
  color: #3f5bd9;
  border-color: #3f5bd9;
}

.rc-slider-handle:hover{
  border-color: #3f5bd9;
}

.rc-slider-track, .rc-slider-tracks{
  background-color: #3f5bd9;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #3f5bd9;
  /* box-shadow: 0 0 0 5px #3f5bd9; */
}

.rc-slider-handle:focus-visible{
  border-color: #3f5bd9;
  box-shadow: 0 0 0 3px #3f5bd9;
}

.form-all form textarea.pt-5{
  padding-top: 5px!important;
}`, "",{"version":3,"sources":["webpack://./src/components/youngwomens/UpdateAwarenessSurvey.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,mCAAmC;AACrC;;AAEA;EACE,qBAAqB;EACrB,6BAA6B;AAC/B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".update-awareness-survey.form-all form label{\r\n  position: relative;\r\n}\r\n\r\n.rating-caption{\r\n  font-size: 12px;\r\n  color: #818181;\r\n}\r\n\r\n.rating-value{\r\n  font-size: 12px;\r\n}\r\n\r\n.rc-slider{\r\n  /* margin: 0 6px; */\r\n}\r\n\r\n.rc-slider-handle{\r\n  color: #3f5bd9;\r\n  border-color: #3f5bd9;\r\n}\r\n\r\n.rc-slider-handle:hover{\r\n  border-color: #3f5bd9;\r\n}\r\n\r\n.rc-slider-track, .rc-slider-tracks{\r\n  background-color: #3f5bd9;\r\n}\r\n\r\n.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {\r\n  border-color: #3f5bd9;\r\n  /* box-shadow: 0 0 0 5px #3f5bd9; */\r\n}\r\n\r\n.rc-slider-handle:focus-visible{\r\n  border-color: #3f5bd9;\r\n  box-shadow: 0 0 0 3px #3f5bd9;\r\n}\r\n\r\n.form-all form textarea.pt-5{\r\n  padding-top: 5px!important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
