// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jumbotron{
  background-color: #f9f9f9;
  border: 1px solid #e7e7e7;
}

.account .thumb{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.account ul{
  padding: 30px 0;
  margin: 0;
}

.account ul li{
  border: 1px solid #e7e7e7;
  padding: 8px 15px;
  border-radius: 6px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.account ul li svg{
  margin-right: 10px;
}

.account ul li a{
  text-decoration: none;
  color: #818181;
  font-size: 14px;
}

.profile-thumb-box{
  position: relative;
  display: inline-block;
}

.profile-thumb-box svg{
  position: absolute;
  bottom: 10px;
  right: -6px;
  color: #000;
}

.red-text{
  color: #c00303;
}

.green-text{
  color: rgb(0, 145, 55);
}`, "",{"version":3,"sources":["webpack://./src/components/Account.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".jumbotron{\r\n  background-color: #f9f9f9;\r\n  border: 1px solid #e7e7e7;\r\n}\r\n\r\n.account .thumb{\r\n  width: 50px;\r\n  height: 50px;\r\n  border-radius: 50%;\r\n}\r\n\r\n.account ul{\r\n  padding: 30px 0;\r\n  margin: 0;\r\n}\r\n\r\n.account ul li{\r\n  border: 1px solid #e7e7e7;\r\n  padding: 8px 15px;\r\n  border-radius: 6px;\r\n  margin-bottom: 15px;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.account ul li svg{\r\n  margin-right: 10px;\r\n}\r\n\r\n.account ul li a{\r\n  text-decoration: none;\r\n  color: #818181;\r\n  font-size: 14px;\r\n}\r\n\r\n.profile-thumb-box{\r\n  position: relative;\r\n  display: inline-block;\r\n}\r\n\r\n.profile-thumb-box svg{\r\n  position: absolute;\r\n  bottom: 10px;\r\n  right: -6px;\r\n  color: #000;\r\n}\r\n\r\n.red-text{\r\n  color: #c00303;\r\n}\r\n\r\n.green-text{\r\n  color: rgb(0, 145, 55);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
