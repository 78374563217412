// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.periodic-data p{
  font-size: 12px;
  margin-bottom: 0;
}

.saved-periodic-data .row .col-6{
  margin-bottom: 20px;
}

.category{
  background: #f3f3f3;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.periodic-data .btn-delete{
  right: 0;
  top: 0;
}


.masonry {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 16px;
}

.masonry-item {
  page-break-inside: avoid;
  break-inside: avoid;
}`, "",{"version":3,"sources":["webpack://./src/components/youngwomens/UpdatePeriodicData.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,QAAQ;EACR,MAAM;AACR;;;AAGA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;AACX;;AAEA;EACE,wBAAmB;EAAnB,mBAAmB;AACrB","sourcesContent":[".periodic-data p{\r\n  font-size: 12px;\r\n  margin-bottom: 0;\r\n}\r\n\r\n.saved-periodic-data .row .col-6{\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.category{\r\n  background: #f3f3f3;\r\n  padding: 10px 20px;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.periodic-data .btn-delete{\r\n  right: 0;\r\n  top: 0;\r\n}\r\n\r\n\r\n.masonry {\r\n  display: grid;\r\n  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));\r\n  gap: 16px;\r\n}\r\n\r\n.masonry-item {\r\n  break-inside: avoid;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
