// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container{
  max-width: 375px;
  margin: 0 auto;
}

.login-container svg{
  color: #818181;
}

.login-box{
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 50px;
  border: 1px solid #eeeeee;
  padding: 30px 38px;
}

.login-box h2{
  font-size: 22px;
}

.login-box p{
  color: #818181;
}

.login-box form{
  width: 100%;
}

.login-box form .form-group{
  margin-bottom: 20px;
}

.login-box form label{
  color: #818181;
  position: absolute;
  padding: 10px;
  font-size: 12px;
}

.login-box form input{
  /* border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 0;
  background-clip: text; */

  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.login-box form .form-group{
  position: relative;
}

.login-box form .form-group .icon-font{
  position: absolute;
  right: 10px;
  top: 20px;
}

.login-box img{
  max-width: 150px;
}

.link-red a{
  /* color: #6d0000; */
  text-decoration: none;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/LogIn.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE;;;;;0BAKwB;;EAExB,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[".login-container{\r\n  max-width: 375px;\r\n  margin: 0 auto;\r\n}\r\n\r\n.login-container svg{\r\n  color: #818181;\r\n}\r\n\r\n.login-box{\r\n  /* height: 100vh; */\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  width: 100%;\r\n  margin-top: 10px;\r\n  margin-bottom: 50px;\r\n  border: 1px solid #eeeeee;\r\n  padding: 30px 38px;\r\n}\r\n\r\n.login-box h2{\r\n  font-size: 22px;\r\n}\r\n\r\n.login-box p{\r\n  color: #818181;\r\n}\r\n\r\n.login-box form{\r\n  width: 100%;\r\n}\r\n\r\n.login-box form .form-group{\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.login-box form label{\r\n  color: #818181;\r\n  position: absolute;\r\n  padding: 10px;\r\n  font-size: 12px;\r\n}\r\n\r\n.login-box form input{\r\n  /* border-radius: 0;\r\n  border-top: none;\r\n  border-left: none;\r\n  border-right: none;\r\n  padding: 0;\r\n  background-clip: text; */\r\n\r\n  padding-top: 1.825rem;\r\n  padding-bottom: 0.625rem;\r\n}\r\n\r\n.login-box form .form-group{\r\n  position: relative;\r\n}\r\n\r\n.login-box form .form-group .icon-font{\r\n  position: absolute;\r\n  right: 10px;\r\n  top: 20px;\r\n}\r\n\r\n.login-box img{\r\n  max-width: 150px;\r\n}\r\n\r\n.link-red a{\r\n  /* color: #6d0000; */\r\n  text-decoration: none;\r\n  font-weight: 500;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
