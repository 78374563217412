// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-all form textarea {
  padding-top: 1.825rem;
}

.update-medical-history label.no-style {
  padding: 0;
  font-size: 16px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/janani/JananiMedicalHistory.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".form-all form textarea {\r\n  padding-top: 1.825rem;\r\n}\r\n\r\n.update-medical-history label.no-style {\r\n  padding: 0;\r\n  font-size: 16px;\r\n  position: relative;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
