// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verification{
  /* height: 100vh; */
  justify-content: flex-start;
}

.verification input[type="text"]{
    width: 40px;
    height: 40px;
    border: 1px solid #e7e7e7;
    text-align: center;
    font-size: 34px;
    padding-top: 10px;
    border: revert;
}`, "",{"version":3,"sources":["webpack://./src/components/Verification.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".verification{\r\n  /* height: 100vh; */\r\n  justify-content: flex-start;\r\n}\r\n\r\n.verification input[type=\"text\"]{\r\n    width: 40px;\r\n    height: 40px;\r\n    border: 1px solid #e7e7e7;\r\n    text-align: center;\r\n    font-size: 34px;\r\n    padding-top: 10px;\r\n    border: revert;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
